import '../theme/tailwind.css';
import React from 'react';
import { AppProps } from 'next/app';
import Script from 'next/script';

// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid';
import { theme } from '../theme';
import { SEGMENT_KEY } from '../constants/app';
import { useRouter } from '../hooks';
import { SegmentScript, useSegmentPageTransition } from '../wag-react/components/segment';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
const MyApp = ({
  Component,
  pageProps
}: AppProps) => {
  const {
    Layout
  } = Component as AppProps['Component'] & {
    Layout?: React.FC<React.PropsWithChildren<unknown>>;
  };

  // This implements the default behavior from styled-components v5
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  };
  const router = useRouter();
  useSegmentPageTransition(router.asPath);
  return Layout ? <Layout {...pageProps} data-sentry-element="Layout" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <SegmentScript as={Script} segmentKey={SEGMENT_KEY} data-sentry-element="SegmentScript" data-sentry-source-file="_app.tsx" />
      <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
    </Layout> : <StyleSheetManager shouldForwardProp={shouldForwardProp} data-sentry-element="StyleSheetManager" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
        <SegmentScript as={Script} segmentKey={SEGMENT_KEY} data-sentry-element="SegmentScript" data-sentry-source-file="_app.tsx" />
        <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
      </ThemeProvider>
    </StyleSheetManager>;
};
export default MyApp;